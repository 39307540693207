<template>
  <div class="experience">
    <div class="bg"><img :src="bg" alt=""></div>
    <div class="main">
      <div class="str">
        <h2>开始合作</h2>
        <p>让医键通成为您企业的助手。接下来我们一起讨论下一步该做什么，完全按照您的需求定制。一切就是这么简单！</p><br>
        <p>由于这类软件在市面上品质参差不齐，我们把医键通的功能定义为由模块组成的辅助软件。我们会告诉你如何找到你所需要的模块，并且去掉你不想要的，获得您最想要的的信息。</p>
      </div>
      <div class="coo"><img :src="coo" alt=""></div>
      <div class="putit">
        <h2>获得软件体验</h2>
        <div class="inputstr" 
              v-for="(item,index) in putit" 
              :key="index"
              >
              <input type="text" :placeholder="item.name" v-model="item.input2">
        </div>
        <div class="inputput" @click="gogogo">提交申请</div>
      </div>
    </div>
    <foots/>
  </div>
</template>

<script>
import axios from 'axios'
import foots from '@/components/pc/foot'
export default {
    components:{
          foots,
      },
    name:'experience',
    data(){
      return{
        input2:'',
        bg:require('@/assets/img/pcImg/experienceImg/banner.png'),
        coo:require('@/assets/img/pcImg/experienceImg/coo.png'),
        putit:[
           {name:'企业名称',input2:'',ename:'企业名称：'},
          {name:'如何称呼您',input2:'',ename:'姓名：'},
          {name:'手机号码',input2:'',ename:'手机：'},
          {name:'需要咨询的功能',input2:'',ename:'问题：'},
        ]
      }
    },
    methods:{
      gogogo(){
        let j=0
        for(var i=0;i<this.putit.length;i++){
          if(this.putit[i].input2 == '' ){
            // alert('请输入内容')
            j++
          }
        }
        let fromdata = new FormData()
        let str = ''
        this.putit.forEach((v,i)=>{
          str += v.ename  + ' ' + v.input2 + ' '
        })
        fromdata.append('content',str)
        if(j==0){
          axios({
              method: 'post',
              url: 'https://say.ektlang.com/ektlang/sendemail',
              data: fromdata
            }).then(res => {
              console.log(res)
              if(res.data.success){
                alert('发送成功')
                this.putit[0].input2 = ''
                this.putit[1].input2 = ''
                this.putit[3].input2 = ''
                this.putit[2].input2 = ''
              }else{
                alert(res.data.msg)
              }
            });
        }else{
          alert('请输入内容')
        }

      }
    }
    
    
}
</script>

<style lang="less" scoped>
input::-webkit-input-placeholder {
color:#697598; /*WebKit browsers*/
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 4 to 18 */
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 19+ */
}
input::-ms-input-placeholder {
color:#697598 ; /*Internet Explorer 10+*/
}

input::-webkit-input-value {
color:black; /*WebKit browsers*/
}
input::-moz-value {
color:black ; /*Mozilla Firefox 4 to 18 */
}
input::-moz-value {
color:black ; /*Mozilla Firefox 19+ */
}
input::-ms-input-value {
color:black ; /*Internet Explorer 10+*/
}
.experience{
  .bg{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    position: fixed;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .main{
    width: 380px + 222px + 418px;
    height: 547px + 50px;
    // background-color: aquamarine;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -250px 0 0 -500px;
    padding: 0;
    .str{
      // background-color: blue;
      margin-top: -10px;
      width: 418px;
      height: 209px;
      color: #ffffff;
      h2{
        margin-bottom: 40px;
      }
      p{
        text-indent:1em;
      }
    }
    .coo{
      width: 458.94px;
      height: 406.35px;
      margin: -30px 0 0 20px;
      img{
        // background-color: brown;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }.putit{
      width: 380px;
      height: 547px;
      background: #FFFFFF;
      box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
      opacity: 1;
      border-radius: 6px;
      position: absolute;
      top: 0;
      right: 0;
      h2{
        margin-top: 40px;
        margin-left: 106px;
        margin-bottom: 40px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 37px;
        color: #31384E;
        -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
        opacity: 1;
      }
      .inputstr{
        width: 332px;
        height: 54px;
        background: #FBFBFB;
        opacity: 1;
        border-radius: 6px;
        margin: 0 0 30px 24px;
        input{
          width: 310px;
          height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color:  black;
          opacity: 0.9;
          margin: 17px 0 0 10px;
          background-color:  #FBFBFB;
        }
      }
      .inputput{
        width: 332px;
        height: 54px;
        background: linear-gradient(132deg, #2655EE 0%, #34B3FF 100%);
        box-shadow: 0px 2px 16px rgba(32, 123, 250, 0.5);
        opacity: 1;
        border-radius: 6px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin: 0 0 0 24px;
        cursor:pointer;
        text-align: center;
        line-height: 54px;
      }
      .inputput:hover{
         box-shadow:none;
      }
    }
  }
  /deep/ .foot{
        position: fixed;
        bottom: 0;
        background: none;
        .content{
            color:  #FFFFFF;
            a {
            color:  #FFFFFF;
            }
        }
    }
}
  
</style>
